import React from 'react';
import logo from './img/logo.png';


function Login() {

    var generateRandomString = function (length) {
        var text = '';
        var possible = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';

        for (var i = 0; i < length; i++) {
            text += possible.charAt(Math.floor(Math.random() * possible.length));
        }
        return text;
    };

    function loginClick() {
        var spotify_client_id = '053ff6bfdf5f431e924eab44dbcabeec';

        var spotify_redirect_uri = 'https://hitster.zachwerden.com/callback.html';
        //var spotify_redirect_uri = 'http://localhost:3000/callback.html';

        var scope = "streaming user-read-email user-read-private"
        var state = generateRandomString(16);

        var auth_query_parameters = new URLSearchParams({
            response_type: "code",
            client_id: spotify_client_id,
            scope: scope,
            redirect_uri: spotify_redirect_uri,
            state: state
        })

        window.location = 'https://accounts.spotify.com/authorize/?' + auth_query_parameters.toString();
    }

    return (
        <div className="App">
            <header className="App-header">
                <img className="logo" src={logo} alt="Logo" />
                <button className="btn-spotify" onClick={loginClick} >
                    Login with Spotify 
                </button>
            </header>
        </div>
    );
}

export default Login;

