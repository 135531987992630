import React, { useState, useEffect } from 'react';
import EmbedPlayback from './EmbedPlayback';
import Login from './Login'
import './App.css';

function App() {

  const [token, setToken] = useState('');

  useEffect(() => {

    async function getToken() {
      let storedToken = sessionStorage.getItem("token");
      if (storedToken) {
        let json = JSON.parse(storedToken);
        setToken(json.access_token);
      }
    }

    getToken();

  }, []);

  return (
    <>
        <div className='header'>(Holiday Hitster)</div>
        { (token === '') ? <Login/> : <EmbedPlayback token={token} /> }
        <div className='footer'>Made by Zach and AJ</div>
    </>
  );
}


export default App;
