import React, { useState, useEffect } from 'react';
import QrReader from 'react-qr-scanner';

function EmbedPlayback(props) {

    const [showScanner, setShowScanner] = useState(true);
    const [scanResult, setScanResult] = useState('');
    const [trackData, setTrackData] = useState(null);
    const [reveal, setReveal] = useState(false);
    const [showPlayer, setShowPlayer] = useState(false);

    const [logEnabled] = useState(false);
    const [log, setLog] = useState([]);

    useEffect(() => {                        
    }, []);

    function logMsg(msg) {
        if (logEnabled) setLog([...log, msg]);
    }

    function revealAnswer() {
        logMsg('Reveal clicked!');
        setReveal(true);
        console.log(scanResult);
        if (!trackData) {
            setShowPlayer(true);
        }
    }

    function handleScan(data){
        if (data && data.text) {
            setShowScanner(false);
            setScanResult('spotify:track:'+data.text);

            window.onSpotifyIframeApiReady = (IFrameAPI) => {
                
                var frame = document.createElement("div");
                frame.setAttribute("id", "embed-iframe");
                document.getElementById('embed-wrapper').appendChild(frame);

                const element = document.getElementById('embed-iframe');
                const options = {
                    uri: 'spotify:track:'+data.text
                  };
                const callback = (EmbedController) => {
                    document.querySelectorAll('.scanBtn').forEach(
                        btn => {
                          btn.addEventListener('click', () => {
                            EmbedController.destroy();
                          });
                        }
                    );

                    setTimeout(() => {
                        EmbedController.togglePlay();
                    }, 500);
                    
                };
                IFrameAPI.createController(element, options, callback);
            };

            logMsg('Calling API: https://api.spotify.com/v1/tracks/'+data.text);
            fetch('https://api.spotify.com/v1/tracks/'+data.text, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + props.token
                }
            })
            .then(response => {
                if (!response.ok) {
                    logMsg('API failed: ' + response.status);
                    if (response.statusText) {
                        logMsg(response.statusText);
                    }
                }
                return response.json()
            })
            .then(data => {
                console.log(data);
                logMsg('API success: ' + data.name);
                setTrackData(data);
            });
        }
    }

    function handleError(err){
        console.error(err)
    }

    function scanClicked() {
        setScanResult('');
        setShowScanner(true);
        setReveal(false);
        setShowPlayer(false);
    }

    function renderScanner() {
        if (showScanner) {
            return (
                <QrReader
                    delay={1000}
                    style={previewStyle}
                    onError={handleError}
                    onScan={handleScan}
                    constraints={ { video: { facingMode: { ideal: 'environment' } } } } />
            );
        }
    }

    function renderScanButton() {
        if (!showScanner) {
            return (
                <button className='scanBtn btn-spotify' onClick={scanClicked}>SCAN</button>
            );
        }
    }

    function renderPlayer() {
        return (
            <div id="embed-wrapper" className={showPlayer ? 'showPlayer' : ''}></div>
        );
    }

    function renderCard() {
        if (!showScanner) {
            if (reveal) {
                if (trackData) {
                    return (
                        <div id="embed-overlay">
                            <div id="reveal-artist">{trackData.artists[0].name}</div>
                            <div id="reveal-year">{trackData.album.release_date.substring(0, 4)}</div>
                            <div id="reveal-track">{trackData.name}</div>
                        </div>
                    );
                }               
            } else {
                return (
                    <div id="embed-overlay" onClick={revealAnswer}>
                        <div id="reveal-prompt">Click to Reveal</div>
                    </div>
                );
            }
        }
    }

    function renderLog() {
        if (logEnabled) {
            var messages = log.map(function(msg) {
                return (
                  <div className="log-msg">
                    {msg}
                  </div>
                )
              });
            return (
                <div id="log">
                    {messages}
                </div>
            );
        }
    }

    const previewStyle = {
        height: '80vh',
        width: '100%',
        margin: 'auto'
    }

    return (
        <div className='wrapper'>
            {renderScanner()}
            {renderScanButton()}
            {renderCard()}
            {renderPlayer()}

            {renderLog()}
        </div>
    );
}

export default EmbedPlayback
